import { SystemProps, Flex, Box } from '@storyofams/react-ui'
import { Image as ToolkitImage } from '@storyofams/storyblok-toolkit'
import { Text, Button, Heading, FixedRatio } from '~components'
import {
  SamplePackageTexture as Texture,
  SamplePackageTextureMobile as TextureMobile
} from './svg/SamplePackageTexture'
import styled from 'styled-components';

export const FlexWrapper = styled(Flex)`
  @media screen and (min-width: 1600px){
    margin-left: auto;
    margin-right: auto;
  }
`;


type SamplePackageProps = {
  content: {
    image?: {
      filename?: string,
      alt?: string,
    };
    image_2?: {
      filename?: string,
      alt?: string,
    };
    title?: string;
    description?: string;
    button_text?: string;
    button_link?: {
      cached_url?: string,
    };
    background_color?: {
      color?: string,
    };
    background_texture_color?: {
      color?: string,
    };
    texture_color?: {
      color?: string,
    };
    font_color?: {
      color?: string,
    };
    badge?: {
      filename?: string,
      alt?: string,
    };
    use_badge?: boolean;
    badge_width?: string;
    badge_pos_x?: string;
    badge_pos_y?: string;
    badge_pos_m_x?: string;
    badge_pos_m_y?: string;
  };
  first?: boolean;
} & SystemProps;


export const SamplePackage = ({ content }: SamplePackageProps) => {
  return (
    <Flex
      marginTop='0 !important'
      alignItems='center'
      width="100%"
      position={'relative'}
    >
      <Box
        position={'absolute'}
        top={'0'}
        left={'0'}
        width={'100%'}
        height={'100%'}
        overflow={'hidden'}
        display={['none', 'block']}
      >
        <Texture
          background_texture_color={
            content?.background_texture_color
              ? content?.background_texture_color.color
              : 'auto'
          }
          texture_color={
            content?.texture_color
              ? content?.texture_color.color
              : 'auto'
          }
        />
      </Box>
      <Box
        position={'absolute'}
        top={'0'}
        left={'0'}
        width={'100%'}
        height={'100%'}
        overflow={'hidden'}
        display={['block', 'none']}
      >
        <TextureMobile
          background_texture_color={
            content?.background_texture_color
              ? content?.background_texture_color.color
              : 'auto'
          }
          texture_color={
            content?.texture_color
              ? content?.texture_color.color
              : 'auto'
          }
        />
      </Box>
      <FlexWrapper
        width="100%"
        mx={[2, 10]}
        my={[4, 8]}
        maxWidth="1440px"
        justifyContent="space-between"
        flexDirection={['column-reverse', 'row']}
        backgroundColor={
          content?.background_color
            ? content?.background_color.color
            : 'auto'
        }
        zIndex={'1'}
      >
        {!!content?.badge?.filename && content?.use_badge && (
          <Box
            position="absolute"
            left={[`${content?.badge_pos_m_x}%`, `${content?.badge_pos_x}%`]}
            top={[`${content?.badge_pos_m_y}px`, `${content?.badge_pos_y}px`]}
            width={`${content?.badge_width}px`}
            zIndex={1}
          >
            <ToolkitImage
              width="100%"
              src={content?.badge?.filename}
              alt={content?.badge?.alt}
              fluid={500}
            />
          </Box>
        )}
        <Flex
          width={['0%', '33%']}
        >
          {!!content?.image && (
            <FixedRatio
              width="100%"
              ratio={[[448, 444]]}
            >
              <ToolkitImage
                src={content.image?.filename}
                alt={content.image?.alt}
                fluid={1200}
                width={'100%'}
                height={'100%'}
                fit="contain"
              />
            </FixedRatio>
          )}
        </Flex>
        <Flex
          width={['100%', '33%']} 
          pt={[0, 6]}
          pb={['32px', 6]}
          px={[1, 2]}
          flexDirection={'column'}
        >
          {!!content?.title && (
            <Heading
              as="h3"
              variant="sh3"
              fontSize={['35px']}
              lineHeight={['45px', '40px']}
              fontWeight="800"
              width={'100%'}
              color={!!content?.font_color ? content?.font_color.color : 'initial' }
              textAlign={'center'}
            >{content?.title}</Heading>
          )}
          {!!content?.description && (
            <Text
              fontSize={[2, '22px']}
              lineHeight={['20px', '28px']}
              mt={[2, 3]}
              whiteSpace="pre-line"
              width={'100%'}
              textAlign={'center'}
            >{content?.description}</Text>
          )}
          {!!content?.button_text && (
            <Flex
              mt={[5]}
              width="100%"
              justifyContent={'center'}
            >
              <Button
                width={['100%', 'auto']}
                variant="button_type_1"
                to={content?.button_link?.cached_url}
              >
                {content?.button_text}
              </Button>
            </Flex>
          )}
        </Flex>
        <Flex
          width={['100%', '33%']}
        >
          {!!content?.image_2 && (
            <FixedRatio
              width="100%"
              ratio={[[448, 444]]}
            >
              <ToolkitImage
                src={content.image_2?.filename}
                alt={content.image_2?.alt}
                fluid={1200}
                width={'100%'}
                height={'100%'}
                fit="contain"
              />
            </FixedRatio>
          )}
        </Flex>
      </FlexWrapper>
    </Flex>
  );
};
